<template>
  <b-container fluid class="mt-4">
    <!-- Barra de Control -->
    <div class="row my-4">
      <div class="col-12 mb-2">
        <!--    Filter  -->
        <b-form-group
          label="Buscar:"
          label-cols-sm="1"
          label-align-sm="left"
          label-align-md="center"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group>
            <b-form-input
              v-model="filterValue"
              type="search"
              id="filterInput"
              placeholder="Buscar..."
              @keyup.enter ="FilterData()"
              @keyup.esc ="clear()"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="default"
                        size="sm"
                        :disabled="!filterValue"
                        @click="clear()">
                <b-icon-x/>
              </b-button>
              <b-button variant="primary"
                        size="sm"
                        @click="FilterData()" >
                <b-icon-search/>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <!--    Filter  -->
      </div>
      <div class="col-sm-11 offset-sm-1 col-md-6 offset-md-1">
        <!--    Clients & CarrierCompanies  -->
        <b-form-group>
          <b-input-group>
            <b-form-select v-model="companyFilter" :options="carrierCompanies"
                           class="w-50" size="sm" value-field="code" text-field="name"
                           label="Empresas Transportistas" @change="FilterData()">
              <template slot="first">
                <b-form-select-option value="" >Todas las Empresas Transportistas</b-form-select-option>
              </template>
            </b-form-select>
          </b-input-group>
        </b-form-group>
        <!--    Clients & CarrierCompanies  -->
      </div>
      <div class="col-sm-11 offset-sm-1 col-md-5 offset-md-0">
        <!--    Sort & Paginate  -->
        <b-form-group>
          <b-input-group>
            <b-form-select v-model="sortBy" :options="sortByOptions" @change="FilterData()" class="w-50" size="sm" />
            <b-form-select v-model="sortDirection" :options="sortOptions" @change="FilterData()" class="w-25" size="sm" :disabled="!sortBy"/>
            <b-form-select v-model="perPage"  :options="pageOptions" @change="FilterData()" class="w-25" size="sm"/>
          </b-input-group>
        </b-form-group>
        <!--    Sort & Paginate  -->
      </div>
    </div>
  </b-container>
</template>

<script>
import { getGeneralMixin } from '@/mixins/getGeneralMixin'
export default {
  name: 'searchTrucksBar',
  props: {
    sortByOptions: Array,
  },
  mixins: [
    getGeneralMixin,
  ],
  data() {
    return {
      filterValue: '',
      sortOptions: [
        { value: '', text: 'Orden', disabled: true },
        { value: '', text: 'Asc' },
        { value: '-', text: 'Desc' },
      ],
      sortBy: '',
      sortDirection: '',
      currentPage: 1,
      perPage: 15,
      pageOptions: [
        { value: 15, text: '#', disabled: true },
        { value: 10, text: '10' },
        { value: 15, text: '15' },
        { value: 50, text: '50' },
      ],
      carrierCompanies: [],
      companyFilter: '',
    }
  },
  watch: {
    filterValue(newValue) {
      this.$emit('searchValue', newValue)
      this.$emit('currentPageValue', 1)
    },
    companyFilter(newValue) {
      this.$emit('companyValue', newValue)
      this.$emit('currentPageValue', 1)
    },
    sortBy(newValue) {
      this.$emit('sortByValue', newValue)
    },
    sortDirection(newValue) {
      this.$emit('sortDirectionValue', newValue)
    },
    perPage(newValue) {
      this.$emit('perPageValue', newValue)
    },
    currentPage(newValue) {
      this.$emit('currentPageValue', newValue)
    },
  },
  methods: {
    async getMixinInfo() {
      const params = { sort: 'name' }
      await this.getComboData(params, 'carrierCompanies')
      this.carrierCompanies = this.comboData
      this.comboData = []
    },
    FilterData() {
      this.$emit('getData')
    },
    clear() {
      this.filterValue = ''
      this.companyFilter = ''
      this.currentPage = 1
      this.$emit('currentPageValue', 1)
      this.$emit('searchValue', '')
      this.$emit('companyValue', '')
      this.$emit('getData')
    },
  },
  mounted() {
    this.getMixinInfo()
  },
}
</script>
