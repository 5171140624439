<template>
  <b-modal :title="`Detalle ${resourceName}`"
           ref="modal-form"
           size="lg"
           v-model="show"
           @hide="CloseModal"
  >
    <b-container fluid>
      <!-- Form with Model -->
      <a-form-model
        ref="rule-Form"
        :model="form"
        :rules="rules"
        layout="vertical"
        :wrapper-col="{ span: 24}"
      >
        <a-form-model-item label="¿Transporte interno?" ref="own" prop="own">
          <a-switch v-model="form.own"
                    checked-children="SI" un-checked-children="NO"/>
        </a-form-model-item>
        <a-form-model-item ref="carrier_company_id" label="Empresa Transportista" prop="carrier_company_id" >
          <a-select v-model="form.carrier_company_id"
                    placeholder="Seleccionar Empresa Transportista"
                    show-search
                    :filter-option="filterOption"
                    :disabled="carrierCompaniesID!== undefined"
          >
            <a-select-option  v-for="option in carrier_companies" :key="option.jsonApi.id" :value="option.jsonApi.id" >
              {{option.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item ref="logistics_shipment_type_id" label="Tipos Logísticos de Transporte" prop="logistics_shipment_type_id" >
          <a-select v-model="form.logistics_shipment_type_id"
                    placeholder="Seleccionar Tipos Logísticos de Transporte"
                    show-search
                    :filter-option="filterOption"
          >
            <a-select-option  v-for="option in logisticsShipmentTypes" :key="option.jsonApi.id" :value="option.jsonApi.id" >
              {{option.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item ref="tag" label="Placa" prop="tag" >
          <a-input v-model.trim="form.tag"/>
        </a-form-model-item>
        <a-form-model-item ref="maximum_capacity" label="Capacidad Máxima (TN)" prop="maximum_capacity" >
          <a-input v-model.trim="form.maximum_capacity"/>
        </a-form-model-item>
        <div class="row justify-content-center" v-show="!load">
          <responsive-button variant="primary" pill size="sm" text="Guardar" responsive="md" icon="archive-fill" @ClickEvent="onSubmit"/>
          <responsive-button v-if="id !== '0'" variant="danger" pill size="sm" text="Eliminar" responsive="md" icon="trash" @ClickEvent="onDelete"/>
        </div>
      </a-form-model>
      <!-- Form with Model -->

      <!-- Spinner -->
      <my-spinner :load="load"/>

    </b-container>
    <template v-slot:modal-footer>
      <simple-modal-footer @ClickEvent="CloseModal"/>
    </template>
  </b-modal>
</template>

<script>
import { objectArray } from '@/helpers/jsonApiTransformer'
import { Modal, notification } from 'ant-design-vue'

export default {
  name: 'modalContent',
  props: {
    // Muestra - oculta el modal
    modalShow: {
      default: false,
      type: [Boolean],
      required: true,
    },
    // Nombre del recurso
    resourceName: {
      type: [String],
      required: true,
    },
    // Arreglo para el formulario
    modalData: {
      type: [Object, String],
      required: true,
    },
    // Arreglo para el formulario
    carrierCompaniesID: {
      default: undefined,
      required: false,
    },
    // Arreglo para el formulario
    clientsID: {
      default: undefined,
      required: false,
    },
  },
  data() {
    return {
      resource: 'trucks',
      // <!-- Form with Model -->
      id: '0',
      form: {
        carrier_company_id: undefined,
        logistics_shipment_type_id: undefined,
        tag: '',
        maximum_capacity: '',
        own: false,
      },
      // arrays selects
      carrier_companies: [],
      logisticsShipmentTypes: [],
      // Reglas de validación
      rules: {
        logistics_shipment_type_id: [
          { required: true, message: 'El campo Tipos Logísticos de Transporte es obligatorio.', trigger: 'blur' },
        ],
        own: [
          { required: true, message: 'El campo Transporte interno es obligatorio.', trigger: 'blur' },
          { type: 'boolean', message: 'El campo Transporte no es un valor válido.', trigger: 'blur' },
        ],
        tag: [
          { required: true, message: 'El campo Placa es obligatorio.', trigger: 'blur' },
          { min: 2, max: 20, message: 'El campo Etiqueta del Negocio debe contener entre 2 y 20 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Etiqueta del Negocio debe ser de tipo texto.', trigger: 'blur' },
        ],
        maximum_capacity: [
          // { required: true, message: 'El campo Capacidad Máxima es obligatorio.', trigger: 'blur' },
        ],
      },
      load: false,
      // <!-- Form with Model -->
    }
  },
  computed: {
    show: {
      get() {
        return this.modalShow
      },
      set(newValue) {
        return newValue
      },
    },
  },
  watch: {
    modalShow(newValue) {
      if (newValue) {
        this.OpenModal()
      }
    },
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    // <!-- Get array selects data  -->
    getCarrier_companies() {
      this.$store.dispatch('jv/get', ['carrierCompanies', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.carrier_companies = objectArray(tableData)
        })
    },
    getLogisticsShipmentTypes() {
      this.$store.dispatch('jv/get', ['logisticsShipmentTypes', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.logisticsShipmentTypes = objectArray(tableData)
        })
    },
    // <!-- Get array selects data  -->
    // <!-- Open & Close Modal Logic  -->
    OpenModal() {
      this.getCarrier_companies()
      this.getLogisticsShipmentTypes()
      if (typeof this.modalData === 'string') {
        this.id = '0'
        this.form.tag = this.modalData
        this.form.maximum_capacity = '0'
        this.form.own = false
        this.form.logistics_shipment_type_id = undefined
        this.form.carrier_company_id = (this.carrierCompaniesID !== undefined) ? this.carrierCompaniesID : undefined
      } else if (Object.keys(this.modalData).length === 0) {
        //  Store case
        this.id = '0'
        this.form.tag = ''
        this.form.maximum_capacity = '0'
        this.form.own = false
        this.form.logistics_shipment_type_id = undefined
        this.form.carrier_company_id = (this.carrierCompaniesID !== undefined) ? this.carrierCompaniesID : undefined
      } else {
        // Update case
        this.id = this.modalData.jsonApi.id
        this.form.tag = this.modalData.tag
        this.form.maximum_capacity = this.modalData.maximum_capacity
        this.form.own = this.modalData.own
        this.form.logistics_shipment_type_id = this.modalData.logisticsShipmentTypes.jsonApi.id
        if (this.carrierCompaniesID !== undefined) {
          this.form.carrier_company_id = this.carrierCompaniesID
        } else if (this.modalData.carrierCompanies.jsonApi) {
          this.form.carrier_company_id = this.modalData.carrierCompanies.jsonApi.id
        } else {
          this.form.carrier_company_id = undefined
        }
      }
    },
    CloseModal() {
      this.id = '0'
      this.resetForm()
      this.$emit('Close')
    },
    getData() {
      this.$emit('getData')
    },
    // <!-- Open & Close Modal Logic  -->
    // <!-- Store Logic  -->
    onSubmit() {
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          // build the relationships with the form data
          const relationships = {
            logisticsShipmentTypes: {
              data: { id: this.form.logistics_shipment_type_id, type: 'logisticsShipmentTypes' },
            },
          }
          if (this.form.carrier_company_id !== undefined) {
            // Add carrier_company_id
            relationships.carrierCompanies = {
              data: { id: this.form.carrier_company_id, type: 'carrierCompanies' },
            }
          }
          // Default Store Obj
          let action = 'post'
          const formObject = {
            tag: `${this.form.tag}`,
            maximum_capacity: `${this.form.maximum_capacity}`,
            own: this.form.own,
            jsonApi: {
              type: this.resource,
              relationships: relationships,
            },
          }
          if (this.id !== '0') {
            // Update Object
            action = 'patch'
            formObject.jsonApi.id = this.id
            // Update Object
          }
          // Envio del Objecto
          this.load = true
          this.$store.dispatch(`jv/${action}`, [formObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro guardado exitosamente',
              })
              this.getData()
              this.load = false
              this.CloseModal()
            })
            .catch((errors) => {
              console.log(errors)
              this.load = false
            })
          // Envio del Objecto
        } else {
          // Fallo de validación
          return false
        }
      })
    },
    onDelete() {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este registro?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        onOk() {
          // Delete Object
          const deleteObject = {
            jsonApi: {
              type: me.resource,
              id: me.id,
            },
          }
          // Delete Object
          me.$store.dispatch('jv/delete', [deleteObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro eliminado exitosamente',
              })
              me.getData()
              me.load = false
              me.CloseModal()
            })
            .catch((errors) => {
              console.log(errors)
              this.load = false
            })
        },
        onCancel() {
          notification.info({
            message: 'No se ha eliminado el registro',
          })
        },
      })
    },
    // <!-- Store Logic  -->
    resetForm() {
      this.$refs['rule-Form'].resetFields()
    },
  },
}
</script>

<style scoped>

</style>
